import styled from "@emotion/styled";
import { CircularProgress } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { useMobile } from "../../hooks/useMobile";
import { DATE_FORMAT } from "../../lib/constants/Date";
import { SeminarCategory } from "../../lib/interfaces/Seminar";
import { formatDate, formatTimeSlot } from "../../utils";
import Heading from "../Heading";
import { NoResult } from "../NoResult";
import { SeminarCard } from "../SeminarCard";
import { useGetSeminarProgramsByDate } from "./query";

type Props = {
  className?: string;
  categories?: string[];
  page?: number;
  tagIds?: string[];
};

export const SeminarDateList: FunctionComponent<Props> = ({
  className,
  categories,
  page,
  tagIds,
}) => {
  const { isMobile } = useMobile();

  const { data } = useGetSeminarProgramsByDate({
    categories,
    page,
    tagIds,
  });

  if (data?.total === 0) {
    return (
      <NoResultContainer>
        <NoResult>ご指定の条件ではセミナーがありません</NoResult>
      </NoResultContainer>
    );
  }

  return (
    <React.Suspense fallback={<CircularProgress />}>
      {data && (
        <Root className={className}>
          {data.data
            .sort(
              (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
            )
            .map(({ date, programs }, idx) => (
              <React.Fragment key={idx}>
                <_Heading>
                  {formatDate(date, DATE_FORMAT.JP_DATE_FORMAT)}
                </_Heading>
                <SeminarCardsContainer>
                  {programs.map(
                    ({
                      category,
                      endAt,
                      id,
                      seminarId,
                      seminarUrlKeyword,
                      startAt,
                      tags,
                      thumbnail,
                      title,
                    }) => (
                      <SeminarCard
                        key={id}
                        datetime={formatTimeSlot(
                          new Date(startAt),
                          new Date(endAt),
                        )}
                        highlightTagIds={tagIds}
                        id={seminarId}
                        urlKeyword={seminarUrlKeyword}
                        seminarCategory={category as SeminarCategory}
                        tags={tags}
                        thumbnail={thumbnail}
                        title={title}
                        variant={isMobile() ? "vertical" : "horizontal"}
                      />
                    ),
                  )}
                </SeminarCardsContainer>
              </React.Fragment>
            ))}
        </Root>
      )}
    </React.Suspense>
  );
};

const Root = styled.div``;

const NoResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

const _Heading = styled(Heading.H2)`
  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;

const SeminarCardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
